
export const parseFrom = (
  // /v1/admin/meal-plans/preview?clientId=xxx&programId=xxx&month=xxx
  // data
  apiPreview,
  // /v1/nutritionist/meal-plans/default-item
  // data
  apiDefaultItem,
  state,
) => {
  console.log(33333123, apiPreview, apiDefaultItem)
  function calculationNutritionalPropertiesByPortion(
    // /v1/admin/meal-plans/preview?clientId=xxx&programId=xxx&month=xxx
    // data.mealPlanSchedules.mealComposition[x]
    apiPreview_,
    // /v1/nutritionist/meal-plans/default-item
    // data[x]
    apiDefaultItem_) {

    const calc = (dataNutritional_) => {
      let result = apiPreview_.portionDecimal ? (parseFloat(apiPreview_.portionDecimal) * parseFloat(dataNutritional_)) : null
      if (result && !Number.isInteger(result)) result = result.toFixed(2)
      return result
    }
    return {
      carbohydrate: calc(apiDefaultItem_?.carbohydrate),
      fat: calc(apiDefaultItem_?.fat),
      fiber: calc(apiDefaultItem_?.fiber),
      calories: calc(apiDefaultItem_?.calory),
      protein: calc(apiDefaultItem_?.protein),
    }
  }

  return apiPreview?.mealPlanSchedules?.reduce((acc, curr) => {
    // continue when isSkipped
    if (curr?.isSkipped)
      return acc

    curr?.mealComposition?.forEach((it) => {
      // continue when isActive === false
      if (!it.isActive && state === 'form')
        return

      const findDefaultItem = apiDefaultItem?.find((el) => el.foodGroup == it.foodGroup)
      const resultCalc = calculationNutritionalPropertiesByPortion(it, findDefaultItem)
      acc.Energi.current += parseFloat(resultCalc.calories || 0)
      acc.Protein.current += parseFloat(resultCalc.protein || 0)
      acc.Lemak.current += parseFloat(resultCalc.fat || 0)
      acc.Serat.current += parseFloat(resultCalc.fiber || 0)
      acc.Karbohidrat.current += parseFloat(resultCalc.carbohydrate || 0)

    })
    return acc
  }, {
    Energi: {
      order: 1,
      target: parseFloat(apiPreview?.nutritionalConditions?.totalEnergyNeeded || 0),
      current: 0,
      unit: 'kkal',
    },
    Protein: {
      order: 2,
      target: parseFloat(apiPreview?.nutritionalIntake?.proteinRequirement || 0),
      current: 0,
      unit: 'gram',
    },
    Lemak: {
      order: 3,
      target: parseFloat(apiPreview?.nutritionalIntake?.fatRequirement || 0),
      current: 0,
      unit: 'gram',
    },
    Serat: {
      order: 4,
      target: parseFloat(apiPreview?.nutritionalIntake?.fiberNeedsForAgeCategory || 0),
      current: 0,
      unit: 'gram',
    },
    Karbohidrat: {
      order: 5,
      target: parseFloat(apiPreview?.nutritionalIntake?.carbohydrateRequirement || 0),
      current: 0,
      unit: 'gram',
    },
  }) || {}
}